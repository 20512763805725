
<template>
  <div class="flex flex-col items-center ribbon-left ribbon-right bg-fill-light">
    <div class="container md:w-10/12 lg:w-5/12 flex flex-col items-center">
      <div class="md:w-full pb-10 flex flex-col justify-center items-center mt-24 ">
        <!-- <img src="@/assets/image/svg_logo.svg" alt="" class="pb-5 logo-lg hidden md:block" /> -->
        <!-- <img src="@/assets/image/svg_logo.svg" alt="" class="pb-5 md:hidden logo-sm " /> -->
        <p class="text-center text-grayColor text-xs md:text-lg z-10">
          打造最具真實的用餐評論，<br>
          僅限透過Joymap訂位才擁有評論權限唷！
        </p>
      </div>
      <div class="w-10/12 md:w-full bg-white p-10 rounded-lg flex flex-col justify-center items-center shadow z-10">
        <p class="md:text-3xl font-medium pb-5 pt-2">非常感謝您寶貴的評論！</p>
        <p class="text-grayColor text-xs md:text-lg pb-5">歡迎下次再度光臨，謝謝您</p>
        <a href="#" class="text-xs md:text-lg text-grayColor font-medium border-b border-grayColor" @click="backToRestaurant">再次預約</a>
        <img src="@/assets/image/Reviewed_illustration.png" alt="" class="pb-5" />
      </div>
      <!-- <button class=" bg-main rounded-md py-2 px-5 text-white my-5">所有評論</button> -->
      <div>
        <div class="callSection">
          <img class="my-16" src="@/assets/image/twdd_marketing_img.png" alt="">
          <button class="text-main border border-main rounded-md font-bold focus:outline-none" @click="handle">呼叫代駕</button>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "CommentSuccess",
  components: {
    Footer,
  },
  data() {
    return {
      phone: "",
      store_id: "",
    };
  },
  methods: {
    backToRestaurant() {
      let routeUrl = this.$router.resolve({ path: `/store/${this.store_id}` }); 
      window.open(routeUrl.href, '_blank'); 
      // this.$router.push(`/store/${this.store_id}`);
    },
    handle() {
      const url = `${process.env.VUE_APP_CALL_URL}/call?phone=${this.phone}`;
      const noPhoneUrl = `${process.env.VUE_APP_CALL_URL}/call`;

      if (this.phone === undefined || this.phone === "") {
        window.open(noPhoneUrl, "_blank");
      } else {
        window.open(url, "_blank");
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.store_id = JSON.parse(localStorage.getItem("storeID"));
    this.phone = this.$route.params.phone;
  },
};
</script>
